
export default {
  props: ["error"],
  data() {
    return {
      products: [],
      recommended: [
        {
          url: "/catalog/m.frezyderm",
          title: "Frezyderm",
        },
        {
          url: "/catalog/prosopo/kremes-prosopoy/",
          title: "Κρέμες Προσώπου",
        },
        {
          url: "/catalog/m.apivita",
          title: "Apivita",
        },
        {
          url: "/catalog/soma/kremes-somatos/",
          title: "Κρέμες Σώματος",
        },
        {
          url: "/catalog/m.korres",
          title: "Korres",
        },
        {
          url: "/catalog/mallia/sampoyan/",
          title: "Σαμπουάν",
        },
        {
          url: "/catalog/m.la-roche-posay",
          title: "La Roche-Posay",
        },
      ],
      needs: [
        {
          url: "/catalog/tag.anagki.adynatisma",
          title: "Αδυνάτισμα",
        },
        {
          url: "/catalog/tag.anagki.antigiransi",
          title: "Αντιγήρανση",
        },
        {
          url: "/catalog/tag.anagki.antiliaki-prostasia",
          title: "Αντηλιακή Προστασία",
        },
        {
          url: "/catalog/tag.anagki.energeia-tonosi",
          title: "Ενέργεια - Τόνωση",
        },
        {
          url: "/catalog/tag.anagki.enischysi-anospoiitikoy",
          title: "Ενίσχυση Ανοσποιητικού",
        },
        {
          url: "/catalog/tag.anagki.kyttaritida",
          title: "Κυτταρίτιδα",
        },
        {
          url: "/catalog/tag.anagki.osta-arthroseis",
          title: "Οστά - Αρθρώσεις",
        },
        {
          url: "/catalog/tag.anagki.aypnia",
          title: "Αυπνία",
        },
      ],
    };
  },
  async mounted() {
    this.getPageData();
  },
  methods: {
    async getProducts(products) {
      try {
        let host = this.$store.state.host;
        let search = `barcodes=${products.map((p) => `${p.barcode}`).join(",")}`;
        let temp = await this.$axios.$get(
          `${process.env.axiosUrl}/items/sv/store/${this.$store.state.store.store_id}?store_id=${this.$store.state.store.store_id}&fullProductPath=${this.$store.state.store.eshop.fullProductPath}&catalogRoute=${this.$store.state.store.eshop.catalogRoute}&${search}`,
          {
            headers: {
              shopster: host,
              Authorization: `Bearer ${this.$store.state.token}`,
            },
          }
        );
        temp = (temp || {});
        return await this.$hyperItem.prepareItems(
          ((temp || {}).items || []).docs || []
        );
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    async getPageData() {
      try {
        let res = await this.$cms.get({ title: "404", limit: 1000 });
        console.log(res);
        if (res?.docs?.length) {
          let recommended = res.docs.filter((d) => d.type == "search");
          let needs = res.docs.filter((d) => d.type == "need");
          let products = res.docs.filter((d) => d.type == "product");
          if (products) {
            this.products = await this.getProducts(products);
          }
          console.log(this.products);
          this.recommended = recommended;
          this.needs = needs;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
